import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


// font-awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faEnvelope, faLocationArrow, faMapMarker, faPhoneAlt, faCircle, faAngleRight, faBars } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faArrowRight, faFacebook, faInstagramSquare, faPhoneAlt, faEnvelope, faLocationArrow, faMapMarker, faCircle, faAngleRight, faBars)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// vue table
import {ClientTable} from 'vue-tables-3';
Vue.use(ClientTable);

// Vue axios
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

// Vue form wizard
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

// Vue sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// Vue Select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

// Vue Charts
import VueCharts from 'vue-chartjs'
Vue.use(VueCharts);

// Vue tab component
import {Tabs, Tab} from 'vue-tabs-component';
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);

// Vue recaptcha
import { VueRecaptcha } from 'vue-recaptcha';
Vue.component('vue-recaptcha', VueRecaptcha);

// Prevent parent from scrolling
Vue.use(require('vue-prevent-parent-scroll'))

// toast
import Swal from 'sweetalert2'
const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
  });
window.toast = toast;

// Format currency
Vue.filter('toCurrency', function (value, currency) {
  // if (typeof value !== "number") {
  //     return value;
  // }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2
  });
  return formatter.format(value);
});

// Format Date
import moment from 'moment'
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});

// Format values by rounding off
Vue.filter('round', function (value) {
  if (!value) {
    value = 0
  }

  let decimals = 2

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  return value
})

// Format values to percentage
Vue.filter('percentage', function (value) {
  if (!value) {
    value = 0
  }

  let decimals = 2

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  value = value + '%'
  return value
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
