<template>
    <div class="footer">
        <p class="footer__title">"Spark the Passion; Ignite the Talent"</p>
        <div class="footer__row">
           <div class="footer__row--item footer__company">
            <img class="footer__company--logo" src="@/assets/logo-green.png" alt="footer-logo">
            <p class="footer__company--statement">At Discovery Golf, we believe that exceptional golf players start young and golf is the perfect sport for children to learn concentration, patience and discipline while enjoying the game. We nurture the talent of our young ones and help them learn the game the right way. </p>
            <p class="footer__company--socials">
                <font-awesome-icon class="social-icon" icon="facebook" />
                <font-awesome-icon class="social-icon" icon="instagram-square" />
            </p>
           </div>
           <div class="footer__row--item footer__contacts">
            <p class="footer__subtitle">Contacts</p>
            <p class="footer__contacts--contact"><font-awesome-icon class="footer__contacts--icon" icon="map-marker" /> Nairobi</p>
            <a href="tel:+254714777950" class="footer__contacts--contact"><font-awesome-icon class="footer__contacts--icon" icon="phone-alt" />Phone: 0714777950</a>
            <a href="mailto:discoverygolf21@gmail.com" class="footer__contacts--contact"><font-awesome-icon class="footer__contacts--icon" icon="envelope" />Email: discoverygolf21@gmail.com</a>
           </div>
           <div class="footer__row--item footer__links">
            <p class="footer__subtitle">Links</p>
            <router-link v-if="currentRouteName != 'Home'" to="/" class="footer__links--link">Home</router-link>
            <router-link v-if="currentRouteName != 'About'" to="/about" class="footer__links--link">About Us</router-link>
            <router-link v-if="currentRouteName != 'GolfTraining'" to="/golf-training" class="footer__links--link">School Golf Training</router-link>
            <router-link v-if="currentRouteName != 'ResidentialPuttingGreen'" to="/residential-putting-green" class="footer__links--link">Putting Greens & Golf Simulators</router-link>
            <router-link v-if="currentRouteName != 'GolfSupplies'" to="/golf-supplies" class="footer__links--link">Golf Supplies</router-link>
            <router-link v-if="currentRouteName != 'GolfCarts'" to="/golf-carts" class="footer__links--link">Golf Carts</router-link>
            <router-link v-if="currentRouteName != 'DrivingRangeEntertainment'" to="/driving-range-entertainment" class="footer__links--link">Entertainment Center</router-link>
           </div>
        </div>
        <p class="footer__copyright">© Discovery Golf. All Rights Reserved.</p>
    </div>
</template>

<script>
export default {
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {

    &__title {
        text-align: center;
        font-size: $line-height*0.75;
        margin: $line-height 0;
        color: $color-black-medium;
        font-style: italic;

        @media screen and (max-width: 728px) {
            font-size: $font-size-normal;
        }
    }

    &__subtitle {
        text-align: center;
        font-size: $font-size-title;
        color: $color-green-medium;
        font-weight: bold;
        margin-bottom: $line-height;
    }

    &__row {
        width: 100%;
        border-top: 1px solid $color-white-main;
        border-bottom: 1px solid $color-white-main;
        @include grid_row;
        justify-content: space-evenly;
        background-color: $color-green-dark;

        @media screen and (max-width: 728px) {
            @include grid_column;
        }

        &--item {
            height: 40vh;
            width: 30%;
            padding: $line-height $line-height;
            @include grid_column;
            align-items: flex-start;
            justify-content: flex-start;

            &:not(:first-child) {
                border-left: 1px solid $color-white-main;
            }

            @media screen and (max-width: 728px) {
                width: 100%;
                height: auto;
                border: none !important;
                padding: $line-height/2 $line-height;
            }
        }
    }

    &__company {
        &--logo {
            max-height: $line-height*2;
            margin-bottom: $line-height/2;
        }

        &--statement {
            line-height: $line-height;
            font-size: $font-size-text;
            color: $color-white-milk;
        }
    }

    &__links {
        &--link {
            margin-bottom: $line-height/4;
            line-height: $line-height;
            font-size: $font-size-text;
            cursor: pointer;
            color: $color-white-milk;

            &:hover {
                color: $color-green-main;
            }
        }
    }

    &__contacts {
        &--contact {
            margin-bottom: $line-height/4;
            line-height: $line-height;
            font-size: $font-size-text;
            cursor: pointer;
            color: $color-white-milk;
        }

        &--icon {
            margin-right: $line-height/2;
            color: $color-green-main;
        }
    }

    &__copyright {
        text-align: center;
        margin: $line-height/2 0;
        color: $color-black-medium;
    }
}
</style>