<template>
  <span class="nav-parent">
    <div class="nav" :class="{ pageScrolled: scrolled === true }">
      <div class="nav__left">
        <router-link to="/">
        <div class="nav__brand">
          <div v-if="scrolled === false">
            <img class="nav__brand--img" src="@/assets/discovery-golf-logo-green.png" alt="logo">
          </div>
          <div v-else>
            <img class="nav__brand--img" src="@/assets/discovery-golf-logo.png" alt="logo">
          </div>
        </div>
        </router-link>
        <div class="nav__links">
          <router-link to="/" class="nav__links--link">Home</router-link>
          <router-link to="/about" class="nav__links--link">About Us</router-link>
          <router-link to="/golf-training" class="nav__links--link">Golf Training</router-link>
          <router-link to="/residential-putting-green" class="nav__links--link">Putting Greens & Golf Simulators</router-link>
          <router-link to="/golf-supplies" class="nav__links--link">Golf Supplies</router-link>
          <router-link to="/golf-carts" class="nav__links--link">Golf Carts</router-link>
          <router-link to="/driving-range-entertainment" class="nav__links--link">Entertainment Center</router-link>
        </div>
      </div>
      <!-- <router-link title="School Golf Training Program" class="nav__button" to="/register">Register</router-link> -->
      <div class="dropdown-link" @click="handleDropdown()">
        <font-awesome-icon class="dropdown-link__icon" icon="bars" />
      </div>
    </div>
    <div class="dropdown" v-if="showDropdown === true">
      <span @click="changeRoute('/')" class="dropdown__link">Home</span>
      <span @click="changeRoute('/about')" class="dropdown__link">About Us</span>
      <span @click="changeRoute('/golf-training')" class="dropdown__link">Golf Training Program</span>
      <span @click="changeRoute('/residential-putting-green')" class="dropdown__link">Putting Greens & <span class="no-wrap">Golf Simulators</span></span>
      <span @click="changeRoute('/golf-supplies')" class="dropdown__link"><span class="no-wrap">Golf Supplies</span></span>
      <span @click="changeRoute('/golf-carts')" class="dropdown__link"><span class="no-wrap">Golf Carts</span></span>
      <span @click="changeRoute('/driving-range-entertainment')" class="dropdown__link">Entertainment Center</span>
    </div>
  </span>
</template>

<script>
  export default {
    name: 'Nav',
    data() {
      return {
        scrolled: false,
        showDropdown: false
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      changeRoute(route) {
        this.$router.push(route)
        this.handleDropdown()
      },
      handleDropdown() {
        this.showDropdown = !this.showDropdown
      },
      handleScroll () {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          this.scrolled = true
        } else {
          this.scrolled = false
        }
      },
    }
  }
</script>

<style lang="scss" scoped>

.nav-parent {
  @include grid_column;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.dropdown {
  width: 100%;
  height: auto;
  background-color: $color-white-milk;
  @include grid_column;
  padding: $line-height 0;
  z-index: 90;

  &__link {
    text-align: center;
    color: $color-green-main;
    margin: $line-height/2 0;
    cursor: pointer;
  }

  @media screen and (max-width: 728px) {
    margin-top: 12px;
  }

  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.nav {
  width: 100%;
  padding: $line-height/3 $line-height;
  @include grid_row;
  align-items: center;
  z-index: 100;

  @media screen and (max-width: 728px) {
    padding: $line-height/3 $line-height;
  }

  &__left {
    @include grid_row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @media screen and (max-width: 900px) {
      justify-content: space-between;
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      justify-content: space-between;
      width: 60%;
    }
  }

  &__brand {
    cursor: pointer;
    &--img {
      max-height: $line-height*3;
      margin: 4px 0;
      // padding: $line-height/3;

      @media screen and (min-width: 1024px) {
        max-height: $line-height*4;
      }
    }
  }

  &__links {
    margin-left: 5%;
    @media screen and (max-width: 1024px) {
      display: none;
    }

    &--link {
      padding: 0 $line-height/2;
      color: $color-white-main;
      font-size: $font-size-text;
      cursor: pointer;
      margin-top: $line-height/3 !important;

      // @media screen and (min-width: 729px) and (max-width:1024px) {
      //   padding: 0 $line-height/3;
      //   margin-top: $line-height/3 !important;
      // }

      &:hover {
        color: $color-green-medium;
      }
    }

    .router-link-exact-active {
      color: $color-green-medium;
      font-weight: bold;

      @media screen and (max-width: 1024px) {
        color: $color-black-medium;
      }
    }
  }

  .dropdown-link {
    @media screen and (min-width: 1025px) {
      display: none;
    }

    &__icon {
      color: $color-green-main;
      font-weight: 600;
      font-size: $font-size-major;
      cursor: pointer;
    }
  }

  &__button {
    @include button;
    margin: 0;

    @media screen and (max-width: 728px) {
      padding: $line-height/3 $line-height/2;
    }
  }
}

.pageScrolled {
  background-color: $color-white-milk;
  @include bottom-shadow;

  .nav__links--link {
    color: $color-black-medium;
  }

  .router-link-exact-active {
    color: $color-green-medium;
    font-weight: bold;
  }

  .nav__button {
    background-color: $color-green-main;
    color: $color-white-main;
  }

}

.white-border {
  padding: 0 6px;
  border-bottom: 2px solid white;
}

.gray-border {
  padding: 0 6px;
  border-bottom: 2px solid $color-green-main;
}

</style>
