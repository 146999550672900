<template>
  <div id="app">
    <Nav />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from './components/Footer.vue';

export default {
  components: {
    Nav,
    Footer
}
}
</script>

<style lang="scss">
@font-face {
  font-family: 'RobotoFlex-Regular';
  src: url('styles/fonts/RobotoFlex-Regular.ttf');
}

@font-face {
  font-family: 'Sato';
  src: url('styles/fonts/Sato-Medium.ttf');
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('styles/fonts/NunitoSans-Regular.ttf');
}


#app {
  font-family: 'RobotoFlex-Regular', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-text;
  width: 100%;
  overflow-x: hidden;
}

.no-wrap {
  white-space: nowrap;
}

</style>
