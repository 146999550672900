import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
  },
  {
    path: '/golf-training',
    name: 'GolfTraining',
    component: () => import(/* webpackChunkName: "GolfTraining" */ '../views/GolfTraining.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '../views/Register.vue')
  },
  {
    path: '/residential-putting-green',
    name: 'ResidentialPuttingGreen',
    component: () => import(/* webpackChunkName: "ResidentialPuttingGreen" */ '../views/ResidentialPuttingGreen.vue')
  },
  {
    path: '/golf-supplies',
    name: 'GolfSupplies',
    component: () => import(/* webpackChunkName: "GolfSupplies" */ '../views/GolfSupplies.vue')
  },
  {
    path: '/golf-carts',
    name: 'GolfCarts',
    component: () => import(/* webpackChunkName: "GolfCarts" */ '../views/GolfCarts.vue')
  },
  {
    path: '/driving-range-entertainment',
    name: 'DrivingRangeEntertainment',
    component: () => import(/* webpackChunkName: "DrivingRangeEntertainment" */ '../views/DrivingRangeEntertainment.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 }
  }
})

export default router
